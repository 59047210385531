<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t_dashboard_popup") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_handle('create', null)"
              v-if="rolesAccount.includes('Admin')"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="left"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t_dashboard_popup_name')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['name']"
                          :placeholder="$t('t_dashboard_popup_name')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <a
                        class="btn btn-soft-secondary btn-border waves-effect waves-light mx-3"
                        style="width: 80px"
                        @click="fn_showFormTimKiem()"
                      >
                        {{ $t("t-hide") }}
                      </a>
                      <button
                        class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                        style="width: 100px"
                        native-type="submit"
                        @click="fn_TimKiem()"
                      >
                        {{ $t("t-search") }}
                      </button>
                    </b-col>
                  </b-row>
                </el-form>
              </b-card>
            </b-collapse>
          </div>

          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            max-height="550"
            v-loading="loading"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('t_dashboard_popup_name')"
              min-width="200"
              align="center"
              sortable="custom"
            />
            <el-table-column
              :label="$t('t-action')"
              align="center"
              width="150px"
              v-if="rolesAccount.includes('Admin')"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_option')"
                    placement="left"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.row)"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>

                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_delete')"
                    placement="left"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.row)"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
      <el-dialog
        v-model="showDialogActionDashboardPopup"
        :title="`${typeForm === 'createForm' ? $t('t-add') : $t('t-update')}`"
        width="500"
        align-center
        :close-on-click-modal="false"
        :before-close="closeDialog"
      >
        <div class="dialog-form">
          <p>{{ $t("t_dashboard_popup_name") }}</p>
          <el-input
            type="text"
            v-model="nameDashboardPopup"
            clearable
            :placeholder="$t('t_dashboard_popup_name')"
            @change="nameDashboardPopup = nameDashboardPopup.trim()"
          ></el-input>
          <p class="dialog-form__error" v-show="notValidate">
            {{ $t("valid_required") }}
          </p>
          <div class="dialog-form__btns">
            <el-button type="primary" @click="actionDashboardPopup(typeForm)">
              {{ typeForm === "createForm" ? $t("t-add") : $t("t-update") }}
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "../../layouts/main.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import MethodService from "@/service/MethodService";
import Swal from "sweetalert2";
import toastr from "toastr";
import DataForm from "./dataFormDashboardPopup";
import mushroom from "cem-primary-api";
const rolesAccount = ref([]);
import { useI18n } from "vue-i18n";
import { Filter } from "mushroomjs";
const { t } = useI18n();
let loading = ref(true);
const router = useRouter();
const route = useRoute();
let tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] });
const pageSizeDefault = ref(20);
const showDialogActionDashboardPopup = ref(false);
const notValidate = ref(false);
const nameDashboardPopup = ref("");
const typeForm = ref("createForm"); // updateForm or createForm
const idDashboardPopup = ref("");
const closeDialog = () => {
  notValidate.value = false;
  nameDashboardPopup.value = "";
  showDialogActionDashboardPopup.value = false;
};
const renderHtmlAlert = async (idsCardItem, nameDashboardPopup) => {
  let htmlDiv =
    idsCardItem?.length > 0
      ? `<p style="font-weight: bold; margin-left: 20px; text-align: left">Popup <span style=" color: #F56C6C">${MethodService.encodeHtml(
          nameDashboardPopup
        )}</span> đang được sử dụng tại: </p>`
      : t("t_being_assigned_dashboard_popup");
  if (idsCardItem?.length > 0) {
    const resCardItems = await mushroom.card_item.getAllAsync({
      fields: "id,title,card_id",
      filters: Filter.in("id", idsCardItem).build(),
    });

    const idsCard = resCardItems.result.map((item) => item.card_id);
    const resCard = await mushroom.card.getAllAsync({
      fields:
        "id,dashboard,service_id,uc_id,table_screen_id,dynamic_dashboard_id,title",
      filters: Filter.in("id", idsCard).build(),
    });
    for (let item of resCard.result) {
      item.dashboard_name = "";
      switch (item.dashboard) {
        case "main":
          item.dashboard_name = "Dashboard probe";
          break;
        case "customer":
          item.dashboard_name = "Dashboard khách hàng";
          break;
        case "session":
          item.dashboard_name = "Dashboard session";
          break;
        case "service": {
          let service = await mushroom.service.findByIdAsync({
            id: item.service_id,
          });
          item.dashboard_name = "Service " + (service.result.name ?? "");
          break;
        }
        case "uc": {
          let usecase = await mushroom.usecase.findByIdAsync({
            id: item.uc_id,
          });
          item.dashboard_name = "UC " + (usecase.result.name ?? "");
          break;
        }
        case "tablescreen": {
          let table_screen = await mushroom.table_screen.findByIdAsync({
            id: item.table_screen_id,
          });
          item.dashboard_name =
            "Tablescreen " + (table_screen.result.name ?? "");
          break;
        }
        case "dynamic": {
          let dynamic = await mushroom.dynamic_dashboard.findByIdAsync({
            id: item.dynamic_dashboard_id,
          });
          let menu = !dynamic.result
            ? null
            : await mushroom.menu.findByIdAsync({
                id: dynamic.result.menu_id,
              });
          let parent_menu = !menu
            ? null
            : await mushroom.menu.findByIdAsync({
                id: menu.result.parent_id,
              });
          item.dashboard_name =
            !menu && !parent_menu
              ? ""
              : `Menu ${
                  !parent_menu.result ? "" : parent_menu.result.label + " / "
                } ${!menu.result ? "" : menu.result.label}`;
          break;
        }
        default:
          item.dashboard_name = "";
      }
    }

    resCardItems.result.forEach((card_item) => {
      resCard.result.forEach((card) => {
        if (card_item.card_id === card.id) {
          const itemDiv = `<p style="font-size: 14px; text-align: left; margin-left: 30px"><span style="color: #F56C6C">*</span> <span style="color: #409EFF">${MethodService.encodeHtml(
            card.dashboard_name
          )}</span> tại card-item <span style="color: #67C23A">${MethodService.encodeHtml(
            card_item.title
          )}</span> của card <span style="color: #E6A23C">${MethodService.encodeHtml(
            card.title
          )}</span> </p>`;
          htmlDiv += itemDiv;
        }
      });
    });
  }

  Swal.fire({
    icon: "error",
    allowOutsideClick: false,
    width: 900,
    html: htmlDiv,
  });
};
const fn_handle = (type, row) => {
  nameDashboardPopup.value = "";
  if (type === "create") {
    typeForm.value = "createForm";
    showDialogActionDashboardPopup.value = true;
  }
  if (type === "update") {
    typeForm.value = "updateForm";
    idDashboardPopup.value = row.id;
    showDialogActionDashboardPopup.value = true;
    nameDashboardPopup.value = row.name;
  }
  if (type === "delete") {
    Swal.fire({
      title: t("t-warning"),
      text: t("t_delete_dashboard_popup"),
      icon: "question",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.dashboard_popup.deleteAsync({
            id: row.id,
          });
          if (response.result) {
            toastr.success(t("toastr_delete_success"));
            await getListDashboardPopup();
          }
        } catch (e) {
          if (e.code === 500 && e.subCode === 2000) {
            renderHtmlAlert(e.result.card_item_ids, row.name);
          } else MethodService.showError(e.code);
        }
      }
    });
  }
};
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  router
    .replace(MethodService.routerReplace("DashboardPopup", tableRules))
    .catch(() => {});
};
const fn_TimKiem = () => {
  if (tableRules.dataSearch.value["name"]) {
    tableRules.dataSearch.value["name"] =
      tableRules.dataSearch.value["name"].trim();
  }
  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  tableRules.offset = 0;
  tableRules.sort = "";
  getListDashboardPopup();
};
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  getListDashboardPopup();
};
const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  getListDashboardPopup();
};
const hashUrl = () => {
  MethodService.hashUrl(route.query, tableRules);
  pageSizeDefault.value = tableRules.limit;
};
const actionDashboardPopup = async (type) => {
  if (!nameDashboardPopup.value) {
    notValidate.value = true;
    return;
  }

  notValidate.value = false;
  type === "createForm"
    ? await createDashboardPopupAsync()
    : await updateDashboardPopupAsync();

  showDialogActionDashboardPopup.value = false;
};
const createDashboardPopupAsync = async () => {
  try {
    const response = await mushroom.dashboard_popup.createAsync({
      name: nameDashboardPopup.value,
    });
    if (response.result) {
      toastr.success(t("toastr_add_success"));
      await getListDashboardPopup();
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const updateDashboardPopupAsync = async () => {
  try {
    const response = await mushroom.dashboard_popup.partialUpdateAsync({
      id: idDashboardPopup.value,
      name: nameDashboardPopup.value,
    });
    if (response.result) {
      toastr.success(t("toastr_update_success"));
      await getListDashboardPopup();
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getListDashboardPopup = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  router
    .replace(MethodService.routerReplace("DashboardPopup", tableRules))
    .catch(() => {});
  try {
    const response = await mushroom.dashboard_popup.listAsync(dataFilter);
    if (response.result) {
      dataTables.value = response.result;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  hashUrl();
  const response = JSON.parse(localStorage.getItem("userInfo"));
  rolesAccount.value = response.result?.roles || [];
  await getListDashboardPopup();
});
</script>
<style lang="scss" scoped>
.dialog-form {
  position: relative;

  &__error {
    color: var(--el-color-danger);
    position: absolute;
    font-size: 12px;
  }

  &__btns {
    margin-top: 25px;
    text-align: center;
  }
}
</style>
